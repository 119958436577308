import { gql } from '@apollo/client';

export const GET_COMMUNITY_MEMBER_ENGAGEMENT_METRICS = gql`
  query CommunityMemberEngagementMetricInsights(
    $communityId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    communityMemberEngagementMetricInsights(
      communityId: $communityId
      startDate: $startDate
      endDate: $endDate
    ) {
      mostActiveMembers {
        userId
        attendedCount
        confirmedCount
        user {
          userId
          firstName
          lastName
          email
          profilePictureUrl
        }
      }
      leastActiveMembers {
        userId
        attendedCount
        confirmedCount
        user {
          userId
          firstName
          lastName
          email
          profilePictureUrl
        }
      }
    }
  }
`;

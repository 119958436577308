import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { Timeframe } from '@epoch-components/DateRangePicker/utils/presets';
import {
  GET_COMMUNITY_CHARTS,
  GET_COMMUNITY_PARTICIPATION_CHART,
  CommunityChartsResult,
  CommunityParticipationChartResult,
  CommunityChart
} from 'graphql/queries/communities/get-community-charts';

type ChartData = Record<string, any>[];

interface ChartsData {
  [key: number]: ChartData;
}

interface ChartsLoading {
  [key: number]: boolean;
}

interface ChartsError {
  [key: number]: boolean;
}

export interface ChartPagination {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  pageSize: number;
}

export interface CommunityChartsState {
  charts: CommunityChart[];
  data: ChartsData;
  loading: ChartsLoading;
  error: ChartsError;
  isLoading: boolean;
  participationRate?: number;
  pagination?: ChartPagination;
  changePage: (newPage: number) => void;
}

interface UseCommunityChartsParams {
  communityId: number;
  startDate: Date | null;
  endDate: Date | null;
  timeframe: Timeframe | null;
}

export default function useCommunityCharts({
  communityId,
  startDate,
  endDate,
  timeframe
}: UseCommunityChartsParams): CommunityChartsState {
  const [chartsData, setChartsData] = useState<ChartsData>({});
  const [chartsLoading, setChartsLoading] = useState<ChartsLoading>({});
  const [chartsError, setChartsError] = useState<ChartsError>({});
  const [participationRate, setParticipationRate] = useState<number | undefined>(undefined);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const { data: chartsConfigData, loading: chartsConfigLoading } = useQuery<CommunityChartsResult>(
    GET_COMMUNITY_CHARTS,
    {
      variables: { communityId },
      skip: !communityId
    }
  );

  const [getChartData] = useLazyQuery<CommunityParticipationChartResult>(
    GET_COMMUNITY_PARTICIPATION_CHART
  );

  const charts = useMemo(() => chartsConfigData?.communityCharts || [], [chartsConfigData]);
  const chartIds = useMemo(() => charts.map((chart) => chart.id), [charts]);

  const changePage = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  async function loadChart(chartId: number): Promise<void> {
    if (!startDate || !endDate) {
      return;
    }

    setChartsError((prevErrors) => ({ ...prevErrors, [chartId]: false }));
    setChartsLoading((prevLoading) => ({ ...prevLoading, [chartId]: true }));

    try {
      // Format dates as YYYY-MM-DD strings for GraphQL
      const formatDateForApi = (date: Date): string => {
        return date.toISOString().split('T')[0];
      };

      const result = await getChartData({
        variables: {
          communityId,
          periodStart: formatDateForApi(startDate),
          periodEnd: formatDateForApi(endDate),
          page: currentPage,
          pageSize
        }
      });

      if (!result || !result.data || !result.data.communityParticipationChart) {
        throw new Error(`No data returned for chartId: ${chartId}`);
      }

      const {
        data: chartPoints,
        overallRate,
        pagination
      } = result.data.communityParticipationChart;

      // Update pagination state
      if (pagination) {
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.total);
        setPageSize(pagination.pageSize);
      }

      // Store the overall participation rate
      setParticipationRate(overallRate);

      // Find the chart configuration
      const chart = charts.find((c) => c.id === chartId);
      if (!chart) {
        throw new Error(`Could not find chart with id: ${chartId}`);
      }

      // Format data for charts
      const formattedData = chartPoints.map((point) => ({
        date: point.pX,
        [chart.metrics[0]]: point.pY,
        weekStart: point.weekStart,
        weekEnd: point.weekEnd,
        totalMembers: point.totalMembers,
        participatingMembers: point.participatingMembers
      }));

      setChartsData((prevData) => ({ ...prevData, [chartId]: formattedData }));
    } catch (error) {
      console.error(`Error loading chart data for chartId: ${chartId}`, error);
      setChartsError((prevErrors) => ({ ...prevErrors, [chartId]: true }));
    } finally {
      setChartsLoading((prevLoading) => ({ ...prevLoading, [chartId]: false }));
    }
  }

  // Load charts when configuration is loaded and when filters change
  useEffect(() => {
    if (chartIds.length > 0 && startDate && endDate) {
      Promise.all(chartIds.map((chartId) => loadChart(chartId)));
    }
  }, [chartIds, communityId, startDate, endDate, timeframe, currentPage]);

  return {
    charts,
    data: chartsData,
    loading: chartsLoading,
    error: chartsError,
    isLoading: chartsConfigLoading,
    participationRate,
    pagination: {
      currentPage,
      totalPages,
      totalItems,
      pageSize
    },
    changePage
  };
}

import { gql } from '@apollo/client';

export interface CommunityEventPerformanceItem {
  event: {
    id: number;
    title: string;
    datetimeEnd: string;
    photoUrl: string;
  };
  communityAttendance: number;
  totalAttendance: number;
  spend: number | null;
}

export interface CommunityEventPerformanceResults {
  communityEventPerformance: {
    items: CommunityEventPerformanceItem[];
    total: number;
    pages: number;
  };
}

export interface CommunityEventPerformanceVariables {
  communityId: number;
  periodStart: string;
  periodEnd: string;
  page: number;
  perPage: number;
  sortBy?: string;
  sortOrder?: string;
}

export const GET_COMMUNITY_EVENT_PERFORMANCE = gql`
  query (
    $communityId: Int!
    $periodStart: Date!
    $periodEnd: Date!
    $page: Int!
    $perPage: Int
    $sortBy: String
    $sortOrder: String
  ) {
    communityEventPerformance(
      communityId: $communityId
      periodStart: $periodStart
      periodEnd: $periodEnd
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      items {
        event {
          id
          title
          datetimeEnd
          photoUrl
        }
        communityAttendance
        totalAttendance
        spend
      }
      pages
      total
    }
  }
`;

import { gql } from '@apollo/client';

export interface CommunityChartDataPoint {
  pX: string; // X-axis value (typically a date)
  pY: number; // Primary Y-axis value
  weekStart: string;
  weekEnd: string;
  totalMembers: number;
  participatingMembers: number;
}

export interface CommunityChartPagination {
  total: number;
  page: number;
  pageSize: number;
  totalPages: number;
}

export interface CommunityParticipationChartResult {
  communityParticipationChart: {
    data: CommunityChartDataPoint[];
    pagination: CommunityChartPagination;
    overallRate: number;
  };
}

export interface CommunityChart {
  id: number;
  communityId: number;
  createdById: number;
  type: string;
  view: string;
  metrics: string[];
  interval?: string;
  groupBy?: string;
  order?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface CommunityChartsResult {
  communityCharts: CommunityChart[];
}

export const GET_COMMUNITY_PARTICIPATION_CHART = gql`
  query CommunityParticipationChart(
    $communityId: Int!
    $periodStart: Date!
    $periodEnd: Date!
    $page: Int
    $pageSize: Int
  ) {
    communityParticipationChart(
      communityId: $communityId
      periodStart: $periodStart
      periodEnd: $periodEnd
      page: $page
      pageSize: $pageSize
    ) {
      data {
        pX
        pY
        weekStart: weekStart
        weekEnd: weekEnd
        totalMembers: totalMembers
        participatingMembers: participatingMembers
      }
      pagination {
        total
        page
        pageSize: pageSize
        totalPages: totalPages
      }
      overallRate: overallRate
    }
  }
`;

export const GET_COMMUNITY_CHARTS = gql`
  query CommunityCharts($communityId: Int!) {
    communityCharts(communityId: $communityId) {
      id
      communityId: communityId
      createdById: createdById
      type
      view
      metrics
      interval
      groupBy: groupBy
      order
      createdAt: createdAt
      updatedAt: updatedAt
    }
  }
`;

import { useMemo, useRef, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_COMMUNITY_INSIGHTS_SUMMARY_METRICS } from 'graphql/queries/communities/get-community-summary-metrics';
import { Timeframe } from '@epoch-components/DateRangePicker/utils/presets';
import { getComparisonMetricsDates } from 'pages/dashboard/overview/insights/hooks/utils';

export interface CommunityMetricsData {
  totalMembers: number;
  newMembers: number;
  totalAttendance: number;
}

export interface CommunitySummaryMetricsResult {
  current: CommunityMetricsData;
  trends: {
    totalMembers: { isPositive: boolean | null; value: string | null };
    newMembers: { isPositive: boolean | null; value: string | null };
    totalAttendance: { isPositive: boolean | null; value: string | null };
  };
  comparisonText: string;
}

export interface CommunitySummaryMetricsState {
  currentMetrics?: CommunityMetricsData;
  trends?: CommunitySummaryMetricsResult['trends'];
  comparisonText?: string;
  loading: boolean;
  refetch: () => void;
}

interface UseCommunitySummaryMetricsParams {
  communityId: number;
  startDate: Date | null;
  endDate: Date | null;
  timeframe: Timeframe | null;
}

const formatDate = (date: Date): string => date.toISOString().split('T')[0];

export default function useCommunitySummaryMetrics({
  communityId,
  startDate,
  endDate,
  timeframe
}: UseCommunitySummaryMetricsParams): CommunitySummaryMetricsState {
  const comparisonDates = useMemo(() => {
    return getComparisonMetricsDates(startDate, endDate, timeframe);
  }, [startDate, endDate, timeframe]);

  const {
    data,
    loading: currentLoading,
    refetch: refetchCurrent
  } = useQuery(GET_COMMUNITY_INSIGHTS_SUMMARY_METRICS, {
    variables: {
      communityId,
      currentPeriodStart: startDate ? formatDate(startDate) : null,
      currentPeriodEnd: endDate ? formatDate(endDate) : null,
      pastPeriodStart: comparisonDates.startDate ? formatDate(comparisonDates.startDate) : null,
      pastPeriodEnd: comparisonDates.endDate ? formatDate(comparisonDates.endDate) : null
    },
    fetchPolicy: 'network-only',
    skip: !startDate || !endDate || !comparisonDates.startDate || !comparisonDates.endDate
  });

  const [loading, setLoading] = useState<boolean>(currentLoading);
  const [currentMetrics, setCurrentMetrics] = useState<CommunityMetricsData | undefined>(
    data?.communitySummaryMetrics?.current
  );
  const [trends, setTrends] = useState<CommunitySummaryMetricsResult['trends'] | undefined>(
    data?.communitySummaryMetrics?.trends
  );
  const comparisonText = data?.communitySummaryMetrics?.comparisonText;

  const isFirstRender = useRef(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (currentLoading) {
      if (isFirstRender.current) {
        setLoading(true);
      } else {
        timeout = setTimeout(() => setLoading(true), 1000);
      }
    } else {
      if (timeout) clearTimeout(timeout);
      setLoading(false);
      if (data?.communitySummaryMetrics) {
        setCurrentMetrics(data.communitySummaryMetrics.current);
        setTrends(data.communitySummaryMetrics.trends);
      }
    }
    isFirstRender.current = false;
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [currentLoading, data?.communitySummaryMetrics]);

  const handleRefetch = (): void => {
    refetchCurrent();
  };

  return { currentMetrics, trends, comparisonText, loading, refetch: handleRefetch };
}

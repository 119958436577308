import React, { FC, createContext, ReactNode, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Timeframe, getPresetRange } from '@epoch-components/DateRangePicker/utils/presets';
import { Community } from 'types/communities.types';

import useCommunitySummaryMetrics, {
  CommunitySummaryMetricsState
} from '../hooks/useCommunitySummaryMetrics';
import useCommunityPerformanceTable, {
  CommunityPerformanceTableState
} from '../hooks/useCommunityPerformanceTable';
import useCommunityCharts, { CommunityChartsState } from '../hooks/useCommunityCharts';
import useMemberEngagementMetrics, {
  MemberEngagementMetricsState
} from '../hooks/useMemberEngagementMetrics';

export interface CommunityInsightsContextState {
  summaryMetrics: CommunitySummaryMetricsState;
  performanceTable: CommunityPerformanceTableState;
  charts: CommunityChartsState;
  memberEngagement: MemberEngagementMetricsState;
  filters: {
    startDate: Date | null;
    endDate: Date | null;
    timeframe: Timeframe | null;
    updateDateRange: (options: {
      range: { from: Date; to: Date };
      timeframe: Timeframe | null;
    }) => void;
  };
}

// Define default values for filters
const defaultTimeframe: Timeframe = Timeframe.thisWeek;
const defaultRange = getPresetRange(defaultTimeframe);
const defaultFilters = {
  startDate: defaultRange.from,
  endDate: defaultRange.to,
  timeframe: defaultTimeframe,
  updateDateRange: ({
    range,
    timeframe
  }: {
    range: { from: Date; to: Date };
    timeframe: Timeframe | null;
  }): void => {
    // Will update if we save views for leaders
    console.info('New date range:', range, 'New timeframe:', timeframe);
  }
};

const defaultSummaryMetrics: CommunitySummaryMetricsState = {
  currentMetrics: { totalMembers: 0, newMembers: 0, totalAttendance: 0 },
  trends: {
    totalMembers: { isPositive: null, value: null },
    newMembers: { isPositive: null, value: null },
    totalAttendance: { isPositive: null, value: null }
  },
  comparisonText: '',
  loading: true,
  refetch: () => {
    // no-op
  }
};

const defaultPerformanceTable: CommunityPerformanceTableState = {
  dataTotal: 0,
  dataCurrentPage: 1,
  dataResultsPerPage: 10,
  loading: true,
  refetch: () => {
    // no-op
  },
  setSortBy: () => {
    // no-op
  },
  setSortOrder: () => {
    // no-op
  },
  handleChangePage: () => {
    // no-op
  }
};

const defaultCharts: CommunityChartsState = {
  charts: [],
  data: {},
  loading: {},
  error: {},
  isLoading: true,
  changePage: () => {
    // no-op
  }
};

const defaultMemberEngagement: MemberEngagementMetricsState = {
  data: null,
  loading: true,
  refetch: () => {
    // no-op
  }
};

const defaultContextValue: CommunityInsightsContextState = {
  summaryMetrics: defaultSummaryMetrics,
  performanceTable: defaultPerformanceTable,
  charts: defaultCharts,
  memberEngagement: defaultMemberEngagement,
  filters: defaultFilters
};

const CommunityInsightsContext = createContext<CommunityInsightsContextState>(defaultContextValue);

interface FiltersState {
  startDate: Date | null;
  endDate: Date | null;
  timeframe: Timeframe | null;
}

export const CommunityInsightsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<FiltersState>({
    startDate: defaultRange.from,
    endDate: defaultRange.to,
    timeframe: defaultTimeframe
  });

  const updateDateRange = ({
    range,
    timeframe
  }: {
    range: { from: Date; to: Date };
    timeframe: Timeframe | null;
  }): void => {
    console.info('New date range:', range, 'New timeframe:', timeframe);
    setFilters({
      startDate: range.from,
      endDate: range.to,
      timeframe
    });
  };

  // Extract the community from the outlet context so that we always work with the current community.
  const { community } = useOutletContext<{ community: Community }>();
  if (!community) {
    throw new Error('Community data is missing in the outlet context.');
  }
  const communityId = community.id;

  const summaryMetrics = useCommunitySummaryMetrics({
    communityId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    timeframe: filters.timeframe
  });

  const performanceTable = useCommunityPerformanceTable({
    communityId,
    startDate: filters.startDate,
    endDate: filters.endDate
  });

  const charts = useCommunityCharts({
    communityId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    timeframe: filters.timeframe
  });

  const memberEngagement = useMemberEngagementMetrics({
    communityId,
    startDate: filters.startDate,
    endDate: filters.endDate
  });

  return (
    <CommunityInsightsContext.Provider
      value={{
        summaryMetrics,
        performanceTable,
        charts,
        memberEngagement,
        filters: {
          ...filters,
          updateDateRange
        }
      }}
    >
      {children}
    </CommunityInsightsContext.Provider>
  );
};

export default CommunityInsightsContext;

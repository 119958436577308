import { gql } from '@apollo/client';

export const GET_COMMUNITY_INSIGHTS_SUMMARY_METRICS = gql`
  query CommunitySummaryMetrics(
    $communityId: Int!
    $currentPeriodStart: Date!
    $currentPeriodEnd: Date!
    $pastPeriodStart: Date!
    $pastPeriodEnd: Date!
  ) {
    communitySummaryMetrics(
      communityId: $communityId
      currentPeriodStart: $currentPeriodStart
      currentPeriodEnd: $currentPeriodEnd
      pastPeriodStart: $pastPeriodStart
      pastPeriodEnd: $pastPeriodEnd
    ) {
      current {
        totalMembers
        newMembers
        totalAttendance
      }
      trends {
        totalMembers {
          isPositive
          value
        }
        newMembers {
          isPositive
          value
        }
        totalAttendance {
          isPositive
          value
        }
      }
      comparisonText
    }
  }
`;

export const EVENT_UPDATE_NOTIFICATIONS = 'event-update-notifications';
export const LIMITED_SLACK_PERMISSIONS = 'limited-slack-permissions';
export const INSIGHTS_DASHBOARD = 'insights-dashboard';
export const INSIGHTS_METRICS_CHARTS = 'insights-default-charts';
export const OUTLOOK_EDITOR_IMAGES = 'outlook-editor-images';
export const AI_INTEGRATION_V1 = 'ai_integration_v1';
export const LARGE_CALENDAR_SPLIT = 'split-large-calendar-events';
export const NOTIFICATIONS_V2 = 'notifications-v2';
export const GOOGLE_MEET = 'google-meet';
export const COMMUNITIES = 'communities';
export const PUBLIC_API = 'public-api';
export const RESET_RSVP = 'reset-rsvps';
export const COMMUNITIES_INSIGHTS = 'communities-insights';

// Invitaion rewrite
export const INVITATION_PROCESSING = 'invitation_processing';
export const GUEST_CAP = 'guest-cap';
export const MULTI_SESSION_CAPACITY = 'multi-session-capacity';

import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  CommunityEventPerformanceResults,
  CommunityEventPerformanceVariables,
  GET_COMMUNITY_EVENT_PERFORMANCE
} from 'graphql/queries/communities/get-community-event-performance';

const RESULTS_PER_PAGE = 10;

interface CommunityPerformanceTableFilters {
  communityId: number;
  startDate: Date | null;
  endDate: Date | null;
}

export interface CommunityPerformanceTableState {
  data?: CommunityEventPerformanceResults['communityEventPerformance']['items'];
  dataTotal: number;
  dataPages?: number;
  dataCurrentPage: number;
  dataResultsPerPage: number;
  loading: boolean;
  refetch: (
    variables?: Partial<CommunityPerformanceTableFilters> & { sortBy?: string; sortOrder?: string }
  ) => void;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
  handleChangePage: (page: number) => void;
}

const formatDate = (date: Date): string => date.toISOString().split('T')[0];

export default function useCommunityPerformanceTable({
  communityId,
  startDate,
  endDate
}: CommunityPerformanceTableFilters): CommunityPerformanceTableState {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [data, setData] = useState<
    CommunityEventPerformanceResults['communityEventPerformance']['items'] | undefined
  >(undefined);
  const [dataTotal, setDataTotal] = useState(0);
  const [dataPages, setDataPages] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const resultsPerPage = RESULTS_PER_PAGE;

  // Handle null dates
  const effectiveStartDate = startDate || new Date();
  const effectiveEndDate = endDate || new Date();

  const [getCommunityEventPerformance, { data: queryData, loading: queryLoading, refetch }] =
    useLazyQuery<CommunityEventPerformanceResults, CommunityEventPerformanceVariables>(
      GET_COMMUNITY_EVENT_PERFORMANCE,
      {
        variables: {
          communityId,
          periodStart: formatDate(effectiveStartDate),
          periodEnd: formatDate(effectiveEndDate),
          page: currentPage,
          perPage: resultsPerPage,
          sortBy,
          sortOrder
        },
        fetchPolicy: 'cache-and-network'
      }
    );

  // Initial fetch
  useEffect(() => {
    getCommunityEventPerformance();
  }, [getCommunityEventPerformance]);

  // Fetch when sorting or page changes
  useEffect(() => {
    refetch({
      communityId,
      periodStart: formatDate(effectiveStartDate),
      periodEnd: formatDate(effectiveEndDate),
      page: currentPage,
      perPage: resultsPerPage,
      sortBy,
      sortOrder
    });
  }, [sortBy, sortOrder, currentPage, communityId, effectiveStartDate, effectiveEndDate, refetch]);

  useEffect(() => {
    if (queryData?.communityEventPerformance) {
      setData(queryData.communityEventPerformance.items);
      setDataTotal(queryData.communityEventPerformance.total);
      setDataPages(queryData.communityEventPerformance.pages);
    }
    setLoading(queryLoading);
  }, [queryData, queryLoading]);

  const handleChangePage = (page: number): void => {
    setCurrentPage(page);
  };

  const handleRefetch = (
    variables?: Partial<CommunityPerformanceTableFilters> & { sortBy?: string; sortOrder?: string }
  ): void => {
    const newStartDate = variables?.startDate || effectiveStartDate;
    const newEndDate = variables?.endDate || effectiveEndDate;

    // Reset to page 1 if filters are changing
    if (variables?.startDate || variables?.endDate || variables?.communityId) {
      setCurrentPage(1);
    }

    // Update sort state if provided
    if (variables?.sortBy) {
      setSortBy(variables.sortBy);
    }

    if (variables?.sortOrder) {
      setSortOrder(variables.sortOrder);
    }

    const refetchVariables: Partial<CommunityEventPerformanceVariables> = {
      ...(variables?.communityId && { communityId: variables.communityId }),
      periodStart: formatDate(newStartDate),
      periodEnd: formatDate(newEndDate),
      page: variables?.startDate || variables?.endDate || variables?.communityId ? 1 : currentPage,
      perPage: resultsPerPage,
      sortBy: variables?.sortBy ? variables.sortBy : sortBy,
      sortOrder: variables?.sortOrder ? variables.sortOrder : sortOrder
    };

    refetch(refetchVariables);
  };

  return {
    data,
    dataTotal,
    dataPages,
    dataCurrentPage: currentPage,
    dataResultsPerPage: resultsPerPage,
    loading,
    refetch: handleRefetch,
    setSortBy,
    setSortOrder,
    handleChangePage
  };
}

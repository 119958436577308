import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_COMMUNITY_MEMBER_ENGAGEMENT_METRICS } from 'graphql/queries/communities/get-member-engagement-metrics';

export interface MemberEngagementUser {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  profilePictureUrl: string;
}

export interface MemberEngagementMetric {
  userId: number;
  attendedCount: number;
  confirmedCount: number;
  user: MemberEngagementUser;
}

export interface MemberEngagementMetricsData {
  mostActiveMembers: MemberEngagementMetric[];
  leastActiveMembers: MemberEngagementMetric[];
}

export interface MemberEngagementMetricsState {
  data: MemberEngagementMetricsData | null;
  loading: boolean;
  refetch: () => void;
}

interface UseMemberEngagementMetricsParams {
  communityId: number;
  startDate: Date | null;
  endDate: Date | null;
}

const formatDate = (date: Date): string => date.toISOString().split('T')[0];

export default function useMemberEngagementMetrics({
  communityId,
  startDate,
  endDate
}: UseMemberEngagementMetricsParams): MemberEngagementMetricsState {
  const {
    data,
    loading: queryLoading,
    refetch
  } = useQuery(GET_COMMUNITY_MEMBER_ENGAGEMENT_METRICS, {
    variables: {
      communityId,
      startDate: startDate ? formatDate(startDate) : null,
      endDate: endDate ? formatDate(endDate) : null
    },
    fetchPolicy: 'network-only',
    skip: !startDate || !endDate
  });

  const [loading, setLoading] = useState<boolean>(queryLoading);
  const [metricsData, setMetricsData] = useState<MemberEngagementMetricsData | null>(
    data?.communityMemberEngagementMetricInsights || null
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (queryLoading) {
      timeout = setTimeout(() => setLoading(true), 300);
    } else {
      if (timeout) clearTimeout(timeout);
      setLoading(false);
      if (data?.communityMemberEngagementMetricInsights) {
        setMetricsData(data.communityMemberEngagementMetricInsights);
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [queryLoading, data?.communityMemberEngagementMetricInsights]);

  const handleRefetch = (): void => {
    refetch();
  };

  return {
    data: metricsData,
    loading,
    refetch: handleRefetch
  };
}

import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface QueryParamsState {
  searchParams: URLSearchParams;
  setQueryParam: (key: string, value: string | null | undefined) => void;
}

export default function useQueryParams(): QueryParamsState {
  const [searchParams, setSearchParams] = useSearchParams();
  const [localParams, setLocalParams] = useState(() => new URLSearchParams(searchParams)); // local state for search params
  const [isInternalUpdate, setIsInternalUpdate] = useState(false);

  useEffect(() => {
    // sync localParams with searchParams if searchParams change externally
    if (!isInternalUpdate) {
      setLocalParams(new URLSearchParams(searchParams.toString()));
    }
    setIsInternalUpdate(false);
  }, [searchParams]);

  // Sync URL params with local state changes
  useEffect(() => {
    if (localParams.toString() !== searchParams.toString()) {
      setIsInternalUpdate(true);
      setSearchParams(localParams.toString());
    }
  }, [localParams]);

  const setQueryParam = (key: string, value: string | null | undefined): void => {
    setLocalParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      if (value === null || value === undefined) {
        updatedParams.delete(key);
      } else {
        updatedParams.set(key, value);
      }
      return updatedParams;
    });
  };

  return { searchParams: localParams, setQueryParam };
}

type ReminderStatus =
  | 'Going'
  | 'Attended'
  | 'Invited'
  | 'Not going'
  | 'Incomplete'
  | 'Maybe'
  | 'Waitlisted'
  | 'Pending';
type FeedbackStatus =
  | 'Going'
  | 'Invited'
  | 'Not going'
  | 'Incomplete'
  | 'Maybe'
  | 'Waitlisted'
  | 'Pending';
type ReminderGuestList = { [key in ReminderStatus]: number };
type FeedbackGuestList = { [key in FeedbackStatus]: number };

export const REMINDERS_GUEST_LIST: ReminderGuestList = Object.freeze({
  Attended: 1,
  Going: 2,
  Incomplete: 3,
  Waitlisted: 4,
  Maybe: 5,
  'Not going': 6,
  Pending: 7,
  Invited: 8
});

export const FEEDBACK_GUEST_LIST: FeedbackGuestList = Object.freeze({
  Going: 1,
  Incomplete: 2,
  Waitlisted: 3,
  Maybe: 4,
  'Not going': 5,
  Pending: 6,
  Invited: 7
});

export const GUEST_STATUSES = Object.freeze({
  CONFIRMED: 'Going',
  INCOMPLETE: 'Incomplete',
  MAYBE: 'Maybe',
  WAITLISTED: 'Waitlisted',
  PENDING: 'Pending',
  INVITED: 'Invited',
  CANCELLED: 'Not going',
  ATTENDED: 'Attended'
});

export const SCHEDULE_RELATIVE_EVENT_TIME = {
  BEFORE: 'before',
  AFTER: 'after'
};

export const SCHEDULE_UNIT = {
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS'
};

export const FEEDBACK_REQUEST_TYPE = Object.freeze({
  NOW: 'Now',
  SCHEDULE: 'Schedule'
});

export const USER_ROLES = Object.freeze({
  USER: 'USER',
  COMPANY_TEAM_ADMIN: 'COMPANY_TEAM_ADMIN',
  COMPANY_SUPER_ADMIN: 'COMPANY_SUPER_ADMIN',
  EPOCH_ADMIN: 'EPOCH_ADMIN'
});

export const USER_PERMISSIONS = Object.freeze({
  EVENT_WRITE_ALL: 'EVENT_WRITE_ALL',
  EVENT_WRITE_TEAM: 'EVENT_WRITE_TEAM',
  LIBRARY_CONTENT_WRITE_ALL: 'LIBRARY_CONTENT_WRITE_ALL',
  LIBRARY_CONTENT_WRITE_TEAM: 'LIBRARY_CONTENT_WRITE_TEAM',
  PROMOTION_WRITE_ALL: 'PROMOTION_WRITE_ALL',
  PROMOTION_WRITE_OWN: 'PROMOTION_WRITE_OWN',
  ORGANIZATION_WRITE: 'ORGANIZATION_WRITE', // organizations are same thing as teams
  USERS_READ_ALL: 'USERS_READ_ALL',
  USERS_WRITE: 'USERS_WRITE',
  SUPER_ADMIN_WRITE: 'SUPER_ADMIN_WRITE',
  TEAM_ADMIN_WRITE: 'TEAM_ADMIN_WRITE',
  INTEGRATIONS_WRITE: 'INTEGRATIONS_WRITE',
  SSO_INTEGRATIONS_WRITE: 'SSO_INTEGRATIONS_WRITE',
  SETTINGS_READ: 'SETTINGS_READ',
  SETTINGS_WRITE: 'SETTINGS_WRITE',
  SETTINGS_ASSETS_WRITE: 'SETTINGS_ASSETS_WRITE'
});

export const API_ERRORS = Object.freeze({
  ACCESS_DENIED: 'GraphQL error: Access Denied'
});

export const REMINDER_STATUS = Object.freeze({
  SCHEDULED: 0,
  SENT: 1,
  SENDING: 2,
  FAILED: 3,
  CREATING_MESSAGES: 4
});

export const COMMUNICATION_STATUS = Object.freeze({
  SCHEDULED: 'SCHEDULED',
  SENT: 'SENT',
  FAILED: 'FAILED'
});

export const FEEDBACK_STATUS = Object.freeze({
  SCHEDULED: 'SCHEDULED',
  SENT: 'SENT'
});

export const COMMUNICATION_CHANNELS = Object.freeze({
  CALENDAR: 'Calendar',
  SLACK: 'Slack'
});

// TODO: Replace everywhere with QUESTION_TYPE enum in questions.types
export const QUESTION_TYPE = Object.freeze({
  LONG: 'long',
  RADIO: 'radio',
  CHECK: 'check'
});

export const INTEGRATION_TYPES = Object.freeze({
  MEET: 'meet',
  CALENDAR: 'calendar',
  ZOOM: 'zoom'
});

export const INTEGRATION_DISPLAY_NAMES = Object.freeze({
  [INTEGRATION_TYPES.ZOOM]: 'Zoom',
  [INTEGRATION_TYPES.MEET]: 'Google Meet',
  [INTEGRATION_TYPES.CALENDAR]: 'Google Calendar'
});
